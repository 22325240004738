// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    setSelectedComment: (track: any) => void;
    setSelectedMedia: (track: any) => void;
    setSelectedTrack: (track: any) => void;
    setUnreadReplies: (replies: any) => void;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    showLoader: boolean;
    tracks: any;
    showCommentChatBox:boolean;
    selectedComment:any;
    replies:any;
    showMediaDetail:boolean;
    selectedTab:string;
    newComment: any;
    apiToken: any;
    repliesCount: any;
    searchTerm: any;
    userType: any;
    commentsCount: any;
    selectedCommentsList: any;
    selectedCommentForReply: any;
    selectedTrack: any;
    selectedTrackMediaDetails: any;
    openMessageLink: any;
    AlertMessage: any;
    openAlertDialog: any;
    selectedTrackIndex: any;
    anchorEl: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CommentListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apigetListnerCommentsApiCallId: string = "";
    apigetSearchTermCommentedTracksCallId: string = "";
    apigetSelectedListnerTrackComments: string = "";
    apipostSendCommentTrackCallId: string = "";
    apipostReplyOnCommentCallId: string = "";
    apigetUnreadRepliesCallId: string = "";
    apigetTrackBioDetailsCallId: string = "";
    apigetCommentRepliesDetailsCallId: string = "";
    apigetFollowUserListenerCallId: string = "";
    unFollowUser: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            showLoader: false,
            tracks: [],
            showCommentChatBox:false,
            apiToken: localStorage.getItem('token'),
            userType:localStorage.getItem('user_type') || '',
            selectedCommentForReply: '',
            selectedComment:'',
            selectedCommentsList: [],
            selectedTrackMediaDetails: '',
            replies:[],
            showMediaDetail:false,
            selectedTab:'track',
            newComment: '',
            selectedTrack: '',
            repliesCount: 0,
            commentsCount: 0,
            searchTerm: '',
            openMessageLink: false,
            AlertMessage: '',
            openAlertDialog: false,
            selectedTrackIndex: null,
            anchorEl: null
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getCommentedTracks()
        if(this.state.selectedTrack == ''){
          this.getUnreadReplies()
        }
        // Customizable Area End
    }

    // Customizable Area Start
    viewTrackDetail = (track:any) => {
      this.props.setSelectedTrack(track)
        this.props.setSelectedComment(track)
        this.props.setSelectedMedia(track)
        this.getTrackMediaDetails(track)
        this.setState({selectedTrack: track, selectedCommentForReply: ''})
        if(window.innerWidth <= 1239){
            this.setState({
                selectedComment:track,
                showMediaDetail:true,
            })
      }
    }
    viewTrackComments = (track:any) => {
        this.props.setSelectedComment(track)
        this.props.setSelectedTrack(track)
        this.props.setSelectedMedia(track)
        this.setState({selectedTrack: track, selectedCommentForReply: ''})
        if(this.state.userType === 'listener'){
           this.setReplyStatus(track.comment_id)
        }
        if(window.innerWidth <= 1239){
          if(this.state.userType === 'listener'){
            this.getListnerCommentsBySelectedTrack(track.add_track_id);
            this.getCommentReplies(track.comment_id)
            this.fetchCommentReplies();
          }else{
            this.setState({
                selectedComment:track
            }, () => {
                this.fetchCommentReplies()
            })
          }
      }
    }

    handleMenuClose = () => {
      this.setState({anchorEl: null});
    };

    handleMenu = (event: any) => {
      this.setState({anchorEl: event.currentTarget});
    };

    setReplyStatus = (commentId: any) => {
      let comments = Object.assign(this.state.tracks, [])

      for (let comment of comments) {
        if (comment.comment_id == commentId) {
            comment.is_read = true;
            break;
        }
      }
    }

    closeCommentChatDialog = () => {
        this.setState({
            selectedComment:'',
            showCommentChatBox:false,
        })
    }
    sendComment = (mediaId:any) => {

    }

    setFollowUser = (userId: any) => {
      this.followUserListnerFlow(userId)
    }

    followUserListnerFlow = (userId: any) => {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };
      
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apigetFollowUserListenerCallId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/account_block/follow_user?user_id=${userId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
}

    setOnChangeNewComment = (value: any) =>{
        if(value != ''){
         this.setState({newComment: value});
        }
    }
  
      sendNewComment = (value: any) => {
        if(this.state.selectedCommentForReply == ''){
          this.postNewCommentOnTracks()
          const element = document.getElementById('scroll-new-comment-dialog')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
        }else{
          const element = document.getElementById(`comment_id_${this.state.selectedCommentForReply.id}`)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' , block: 'nearest'});
          }
          this.postReplyOnComment()
        }
      }

      getCommentReplies = (CommentId: any) =>{
        const header = {
          "Content-Type": configJSON.dashboarContentType,
          'token': this.state.apiToken
        };
        
       
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.apigetCommentRepliesDetailsCallId = requestMessage.messageId;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `/bx_block_activityfeed/replies?comment_id=${CommentId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      getTrackMediaDetails = (track: any) =>{
        const header = {
          "Content-Type": configJSON.dashboarContentType,
          'token': this.state.apiToken
        };
        
       
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.apigetTrackBioDetailsCallId = requestMessage.messageId;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `/bx_block_audiomusic/expan_track?id=${track.add_track_id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      postNewCommentOnTracks = () => {
        const header = {
          "Content-Type": configJSON.dashboarContentType,
          'token': this.state.apiToken
        };
         
        const data = {
            
            description: this.state.newComment,
            add_track_id: this.state.selectedTrack.add_track_id
        
        };
       
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.apipostSendCommentTrackCallId = requestMessage.messageId;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `/bx_block_activityfeed/comments?description=${this.state.newComment}&add_track_id=${this.state.selectedTrack.add_track_id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
          );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      
      }

      postReplyOnComment = () =>{
        const header = {
          "Content-Type": configJSON.dashboarContentType,
          'token': this.state.apiToken
        };
         
        const data = {
            
            description: this.state.newComment,
            comment_id: this.state.selectedCommentForReply.id
        
        };
       
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.apipostReplyOnCommentCallId = requestMessage.messageId;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `/bx_block_activityfeed/replies`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
          );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      
      }

    handlePageChange = (page:string) => {
        this.setState({
            selectedTab:page
        })
    }
    closeProfileMedia = () => {
        this.setState({
            selectedComment:'',
            showMediaDetail:false,
        })
    }

    selectedComentReply = (comment: any) =>{
      this.setState({selectedCommentForReply: comment})
    }

    closeCommentReply= () =>{
      this.setState({selectedCommentForReply: ''})
    }

    debounce = (callback: any,delay: any)=>{
      let timer: any;
      return(...args: any) =>{
        clearTimeout(timer);
        timer = setTimeout(() =>{
          callback(...args)
        }, delay)
      }
    }

   debounceLog = this.debounce((text: any) => this.getSearchkeyCommentedTracks(text), 1000);

    searchComment =(value: any) =>{
      this.setState({searchTerm: value.target.value})
      this.debounceLog(value.target.value)
    }  

    getSearchkeyCommentedTracks = (searchTerm: any) => {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };
      
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apigetSearchTermCommentedTracksCallId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/bx_block_activityfeed/listener_comments?search=${searchTerm}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
    }

    getListnerCommentsBySelectedTrack = (trackID: any) => {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };
      
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apigetSelectedListnerTrackComments = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/bx_block_activityfeed/show_comment?add_track_id=${trackID}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getUnreadReplies = () => {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };
      
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.apigetUnreadRepliesCallId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/bx_block_activityfeed/unread_reply_comments`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
    }

    getCommentedTracks = async () => {
        const header = {
          "Content-Type": configJSON.dashboarContentType,
          'token': this.state.apiToken
        };
        
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.apigetListnerCommentsApiCallId = requestMessage.messageId;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `/bx_block_activityfeed/listener_comments`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      
      }

      doUnFollow = (userID: any) => {
        const header = { token: localStorage.getItem('token') }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.unFollowUser = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/unfollow_user?user_id=${userID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

      getListnerRepliedTracksApiSuccessCall = (responseJson: any) =>{
        if(responseJson?.data){
          this.setState({ tracks: responseJson?.data, repliesCount: responseJson?.reply_count})
        }
      }

      getSearchTermCommentedTracksApiSuccessCall = (responseJson: any) =>{
        if(responseJson?.data){
          this.setState({ tracks: responseJson?.data, repliesCount: responseJson?.reply_count})
        }else if(responseJson?.message){
          this.setState({ tracks: [], repliesCount: 0})
        }else{
          this.setState({ tracks: [], repliesCount: 0})
        }
      }

      getSelectedListnerTrackCommentsApiSuccessCall= (responseJson: any) => {
        this.setState({selectedCommentsList : responseJson?.data, commentsCount: responseJson?.comments_count})
      }

      postNewCommentOnTracksApiSuccessCall = (responseJson: any) =>{  
      this.setState({selectedCommentsList: [responseJson?.data,...this.state.selectedCommentsList], newComment: ''})
    }

    addReplyToComment = (commentsList: any, reply: any) => {
    let comments = Object.assign(commentsList, [])
    for (let comment of comments) {
        if (comment.attributes.id === reply.attributes.comment_id) {
            comment.attributes.replies = [reply, ...comment.attributes.replies]
            break;
        }
    }
    return comments;
    }

    postReplyOnCommetApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedCommentsList: this.addReplyToComment(this.state.selectedCommentsList, responseJson?.data), selectedCommentForReply: '', newComment: '' })
    }
    getUnreadRepliesApiSuccessCall = (responseJson: any) =>{
      this.props.setUnreadReplies(responseJson?.unread_replies || 0)
   }

   getTrackBioDetailsApiSuccessCall = (responseJson: any) => {
    this.setState({selectedTrackMediaDetails: responseJson?.data})
 }

 handleClose = () => {
  this.setState({openAlertDialog: false})
 }

    getfollowUserListnerApiSuccessCall = (responseJson: any) => {
      this.setState({selectedTrackMediaDetails : this.addStatusToState(true)})
    }

    addStatusToState = (status: any) =>{
      let mediaDetails = Object.assign(this.state.selectedTrackMediaDetails, {})
      mediaDetails.attributes.is_following = status;
      return mediaDetails
    }
    
    getunfollowUserListnerApiSuccessCall = (responseJson: any) => {
      this.setState({selectedTrackMediaDetails : this.addStatusToState(false)})
    }

    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.apigetListnerCommentsApiCallId) {
              this.getListnerRepliedTracksApiSuccessCall(responseJson)
              this.parseApiCatchErrorResponse(errorReponse);
            }else if (apiRequestCallId === this.apigetSearchTermCommentedTracksCallId) {
              this.getSearchTermCommentedTracksApiSuccessCall(responseJson)
              this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetSelectedListnerTrackComments) {
              this.getSelectedListnerTrackCommentsApiSuccessCall(responseJson)
              this.parseApiCatchErrorResponse(errorReponse);         
            }
            else if (apiRequestCallId === this.apipostSendCommentTrackCallId) {
              this.postNewCommentOnTracksApiSuccessCall(responseJson)
              this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apipostReplyOnCommentCallId) {
              this.postReplyOnCommetApiSuccessCall(responseJson)
              this.parseApiCatchErrorResponse(errorReponse);
            }
            else if (apiRequestCallId === this.apigetUnreadRepliesCallId) {
              this.getUnreadRepliesApiSuccessCall(responseJson)
              this.parseApiCatchErrorResponse(errorReponse);
          }
          else if (apiRequestCallId === this.apigetTrackBioDetailsCallId) {
            this.getTrackBioDetailsApiSuccessCall(responseJson)
            this.parseApiCatchErrorResponse(errorReponse);
          }
          else if (apiRequestCallId === this.apigetFollowUserListenerCallId) {
            this.getfollowUserListnerApiSuccessCall(responseJson)
            this.parseApiCatchErrorResponse(errorReponse);
          }
          else if (apiRequestCallId === this.unFollowUser) {
            this.getunfollowUserListnerApiSuccessCall(responseJson)
            this.parseApiCatchErrorResponse(errorReponse);
          }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    fetchCommentReplies = () => { 
        this.setState({
            showCommentChatBox:true
        })
    }
    // Customizable Area End
}
